define("account-talentrh/pods/settings/nela/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Single line computed properties
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    accessAVD: Ember.computed.alias('permission.toAccessAVD'),
    admin: Ember.computed.alias('permission.admin'),
    // Multi line computed properties
    allSettingsFiltered: Ember.computed('accessAVD', 'admin', 'allSettings', 'companyConfig.communicationApi', 'search', 'permission.aiAssistant', function () {
      let search = this.search;
      let allSettings = Ember.A([{
        sectionName: 'Geral',
        settings: [{
          title: 'Links Externos',
          description: 'Habilita a criação e exibição de links externos',
          value: 'permitExternalLink',
          show: true
        }, {
          modalTitle: 'Controle de Lista de Almoço',
          title: 'Controle de Lista de Almoço',
          description: 'Habilita a solicitação e gerenciamento de pedidos de almoço. Para configurar horário, desconto e valor da refeição clique na engrenagem ao lado',
          value: 'manageLunchList',
          componentDetail: 'settings/details/lunch-list',
          show: true
        }, {
          title: 'Exibir Razão Social',
          description: 'Habilita a exibição da razão social da filial ao invés do seu nome fantasia no cartão ponto e recibo de pagamento',
          value: 'showBranchName',
          show: true
        }]
      }, {
        sectionName: 'Rede Social',
        settings: [{
          title: 'Classificados',
          description: 'Habilita a publicação de classificados (anúncios de compra e venda) pelos usuários',
          value: 'permitAd',
          show: true
        }, {
          title: 'Comunicados',
          description: 'Habilita a publicação de comunicados pelos usuários',
          value: 'permitAnnouncement',
          show: true
        }, {
          title: 'Eventos',
          description: 'Habilita a publicação de eventos pelos usuários',
          value: 'permitEvent',
          show: true
        }, {
          title: 'Enquetes',
          description: 'Habilita a publicação de enquetes pelos usuários',
          value: 'permitSurvey',
          show: true
        }, {
          title: 'Notícias',
          description: 'Habilita a publicação de notícias pelos usuários',
          value: 'permitNews',
          show: true
        }, {
          title: 'Posts',
          description: 'Habilita a publicação de posts pelos usuários',
          value: 'permitPostMessage',
          show: true
        }, {
          title: 'Notificar comunicado',
          description: 'Envia notificação por e-mail e Voors Social, à todos os usuários, ao publicar um comunicado',
          value: 'permitAnnouncementEmail',
          show: true
        }, {
          title: 'Reportar Publicações',
          description: 'Habilita a opção Reportar nas publicações e o gerenciamento de reportes',
          value: 'permitComplaint',
          show: true
        }, {
          title: 'Grupos',
          description: 'Permite a criação de grupos pelos usuários',
          value: 'permitGroups',
          show: true
        }, {
          title: 'Chat',
          description: 'Habilita o chat para troca de mensagens (individuais e em grupos) entre os usuários',
          value: 'permitChat',
          show: true
        }, {
          title: 'Gestão do Conhecimento',
          description: 'Permite a gestão do conhecimento pelos usuários',
          value: 'permitForum',
          show: true
        }]
      }, {
        sectionName: 'Colaboradores',
        settings: [{
          title: 'Card de Aniversariantes do Mês',
          description: 'Altera a exibição padrão de aniversariantes do dia para aniversariantes do mês no card do Social.',
          value: 'showBirthdayMonth',
          show: true
        }, {
          title: 'Card de Aniversariantes de Empresa do Mês',
          description: 'Altera a exibição padrão de aniversariantes de empresa do dia para aniversariantes de empresa do mês no card do Social.',
          value: 'showBirthdayCompanyMonth',
          show: true
        }, {
          title: 'Exibir Ausências da Semana',
          description: 'Altera a exibição padrão, de ausências do dia, para exibição de ausências da semana',
          value: 'showReasonWeek',
          show: true
        }, {
          title: 'Mural de Ausências',
          description: 'Habilita a exibição de ausências no mural e no feed',
          value: 'permitMural',
          show: true
        }, {
          title: 'Página de Aniversariantes',
          description: 'Habilita a exibição da lista de aniversários dos usuários',
          value: 'permitAnniversary',
          show: true
        }, {
          title: 'Publicações Aniversário Empresa',
          description: 'Habilita a publicação automática de aniversariantes de empresa do dia',
          value: 'postAnniversaryCompany',
          show: true
        }, {
          title: 'Publicações Aniversário',
          description: 'Habilita a publicação automática de aniversariantes do dia',
          value: 'postAnniversary',
          show: true
        }, {
          title: 'Sentimento do Dia',
          description: 'Habilita a pesquisa do sentimento do dia dos usuários',
          value: 'permitUserFeeling',
          show: true
        }, {
          title: 'Sentimento do Dia no Perfil',
          description: 'Habilita a exibição do sentimento, escolhido pelo usuário no dia, em seu perfil',
          value: 'showFeelingOnProfile',
          show: true
        }, {
          title: 'Exibir Função dos Colaboradores',
          description: 'Alterar a exibição padrão, de cargo dos colaboradores, para função dos colaboradores.',
          value: 'showJobFunction',
          show: true
        }, {
          modalTitle: 'Gerenciar Solicitações a Gestão de Pessoas',
          title: 'Solicitações a Gestão de Pessoas',
          description: 'Habilita o envio de solicitações ao setor Gestão de Pessoas. Para configurar o fluxo clique na engrenagem ao lado',
          value: 'permitMessageRh',
          componentDetail: 'settings/details/manage-requests',
          customSave: true,
          show: true
        }, {
          title: 'Exibir Meu Cargo/Minha Função',
          description: 'Habilita a exibição do card Meu Cargo/Minha Função no Meu Voors.',
          value: 'showJobFunctionCard',
          show: this.accessAVD
        }, {
          title: 'Exibir Minhas Metas',
          description: 'Habilita a exibição do card Minhas Metas no Meu Voors.',
          value: 'showMyGoalsCard',
          show: this.accessAVD
        }, {
          title: 'Obrigar Foto de Perfil',
          description: 'Habilita a obrigatoriedade de inserção de foto de perfil.',
          value: 'requireImageProfile',
          show: true
        }, {
          title: 'Exibir email corporativo',
          description: 'Habilita a exibição do email corporativo a todos os usuários',
          value: 'showCorporateEmail',
          show: true
        }]
      }, {
        sectionName: 'Empresa',
        settings: [{
          title: 'Álbum',
          description: 'Habilita a criação e exibição de álbuns da empresa',
          value: 'permitCompanyPhotos',
          show: true
        }, {
          title: 'Atas de Reunião',
          description: 'Habilita a criação e visualização de atas de reunião',
          value: 'permitMeetingMinute',
          show: true
        }, {
          title: 'Benefícios',
          description: 'Habilita a criação e exibição de benefícios da empresa',
          value: 'permitBenefit',
          show: true
        }, {
          title: 'Lista de contatos',
          description: 'Habilita a exibição da lista de usuários/colaboradores',
          value: 'permitContactList',
          show: true
        }, {
          title: 'Organograma',
          description: 'Habilita a exibição do organograma da empresa',
          value: 'permitChart',
          show: true
        }, {
          title: 'Políticas da Empresa',
          description: 'Habilita a criação e exibição das políticas da empresa',
          value: 'permitPolicy',
          show: true
        }, {
          title: 'Programas da Empresa',
          description: 'Habilita a criação e exibição dos programas da empresa',
          value: 'permitCompanyPrograms',
          show: true
        }, {
          title: 'Tomada de Decisão',
          description: 'Habilita a criação de tomada de decisão através de votação',
          value: 'permitDecisionMaking',
          show: true
        }, {
          title: 'Vagas',
          description: 'Habilita a criação e exibição das vagas da empresa',
          value: 'permitCreateVacancyAnnouncement',
          show: true
        }, {
          title: 'Progressão Profissional',
          description: 'Habilita o quadro de progressão profissional',
          value: 'allowProfessionalProgression',
          show: true
        }]
      }, {
        sectionName: 'Folha de Pagamento',
        settings: [{
          title: 'Banco de Horas',
          description: 'Habilita a visualização do banco de horas pelo usuário',
          value: 'usesCompensatoryTime',
          show: true
        }, {
          title: 'Cartão Ponto',
          description: 'Habilita a visualização do cartão ponto pelo usuário',
          value: 'permitTimeCard',
          show: true
        }, {
          title: 'Comprovante de Rendimentos',
          description: 'Habilita a visualização do comprovante de rendimentos pelo usuário',
          value: 'permitAnnualIncoming',
          show: true
        }, {
          title: 'Recibo de Pagamento',
          description: 'Habilita a visualização do recibo de pagamento pelo usuário',
          value: 'permitReceipt',
          show: true
        }, {
          title: 'Gestão de Assinaturas ',
          description: 'Habilita a utilização da gestão de assinaturas para documentos',
          value: 'subscriptionManagement',
          show: true
        }, {
          title: 'Importações',
          description: 'Permite a importação de arquivos em PDF de recibos de pagamento, cartões ponto e comprovante de rendimento',
          value: 'permitImports',
          show: !this.companyConfig.communicationApi
        }]
      }, {
        sectionName: 'Segurança',
        settings: [{
          title: 'Autenticação 2FA',
          description: 'Habilita aos funcionários da empresa a autenticar em 2FA',
          value: 'permit2FA',
          componentDetail: 'settings/details/authentication2FA',
          customSave: true,
          show: true
        }, {
          title: 'Política de senhas',
          description: 'Habilita o uso de políticas para as senhas de acesso',
          value: 'permitStrongPass',
          componentDetail: 'settings/details/password-requirements',
          customSave: true,
          show: true
        }]
      }]);
      if (this.admin) {
        allSettings.push({
          sectionName: 'Integração',
          settings: [{
            title: 'API de integração',
            description: 'Utiliza as tabelas de integração da API.',
            value: 'communicationApi',
            componentDetail: 'settings/details/communication-api',
            customSave: true,
            show: true
          }, {
            title: 'Integração com Webhook',
            description: 'Habilita a integração com o webhook',
            componentDetail: 'settings/details/webhook-token',
            value: 'allowWebHook',
            show: true
          }, {
            title: 'Integração com ChatGPT',
            description: 'Habilita a integração com o ChatGPT',
            componentDetail: 'settings/details/gpt-token',
            value: 'allowHabitusIa',
            show: this.admin && this.permission.aiAssistant
          }]
        });
      }
      if (!search) {
        return allSettings;
      }
      return allSettings.map(section => {
        let filtered = section.settings.filter(obj => {
          return obj && obj.title.toLowerCase().includes(search.toLowerCase());
        });
        return {
          sectionName: section.sectionName,
          settings: filtered
        };
      });
    }),
    // Tasks
    removeLogo: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Tem certeza que deseja remover esta imagem?');
        if (value) {
          this.swal.loading('Removendo imagem da logo');
          let response = yield this.ajax.request('companyConfigurations/removeAutomaticPostsLogo');
          this.store.pushPayload(response);
          this.swal.close();
        }
      } catch (e) {
        this.swal.error(e);
      }
    }),
    removeThumbLogo: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Tem certeza que deseja remover esta imagem?');
        if (value) {
          this.swal.loading('Removendo imagem da thumb logo');
          let response = yield this.ajax.request('companyConfigurations/removeThumbLogo');
          this.store.pushPayload(response);
          this.swal.close();
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      save(name) {
        let toast = this.toast;
        let companyConfig = this.companyConfig;
        if (name) {
          this.toggleProperty(`companyConfig.${name}`);
        }
        companyConfig.save().then(() => {
          toast.success('Configuração salva com sucesso');
        });
        Ember.set(this, 'openSettingModal', false);
      },
      manageSetting(setting) {
        Ember.set(this, 'settingToManage', setting);
        Ember.set(this, 'openSettingModal', true);
      },
      click(item) {
        document.getElementById(item).click();
      },
      afterUploading(response) {
        this.store.pushPayload(response);
      }
    }
  });
  _exports.default = _default;
});