define("account-talentrh/pods/components/settings/details/password-requirements/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YnqLajc9",
    "block": "{\"symbols\":[\"@settingsManager\"],\"statements\":[[10,\"li\"],[14,0,\"py-0 border-bottom border-gray bg-white my-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row w-100\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-9 d-flex align-items-center\"],[15,\"title\",[32,1,[\"description\"]]],[12],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"d-flex align-items-center\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"text-contas ml-2 d-flex align-items-center justify-content-center border border-primary rounded-circle px-1 font-weight-bold\"],[14,5,\"height: 15px; font-size: 12px; cursor: default;\"],[12],[2,\"?\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control col-3 d-flex mb-2\"],[16,\"placeholder\",[32,1,[\"input\"]]],[24,4,\"number\"]],[[\"@value\",\"@pattern\"],[[30,[36,0],[[32,0,[\"companyPassProp\"]],[32,1,[\"utilize\"]]],null],\"999\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/details/password-requirements/item/template.hbs"
    }
  });
  _exports.default = _default;
});