define("account-talentrh/pods/registry/general/user/edit/change-password/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    permission: Ember.inject.service(),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    companyPassProp: Ember.computed.alias('permission.companyPassProp'),
    user: Ember.computed.alias('model'),
    init() {
      this._super(...arguments);
      this.password = {};
    },
    changePassword: (0, _emberConcurrency.task)(function* () {
      if (!this.password.new) {
        return this.swal.warning('É necessário informar uma senha');
      }
      if (!this.password.confirmation) {
        return this.swal.warning('É necessário confirmar a senha');
      }
      if (this.password.new !== this.password.confirmation) {
        return this.swal.warning('As senhas informadas não coincidem');
      }
      try {
        yield this.ajax.post(`users/${this.user.id}/changePassword`, {
          data: this.password
        });
        this.toast.success('Senha alterada');
        this.transitionToRoute('registry.general.user.edit.index');
      } catch (e) {
        Ember.debug(e);
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      close() {
        this.transitionToRoute('registry.general.user.edit.index');
      }
    }
  });
  _exports.default = _default;
});