define("account-talentrh/pods/reset-password/controller", ["exports", "ember-concurrency-decorators", "account-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ResetPasswordController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class ResetPasswordController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "token", _descriptor4, this);
      _initializerDefineProperty(this, "password", _descriptor5, this);
      _initializerDefineProperty(this, "confirmPassword", _descriptor6, this);
      _initializerDefineProperty(this, "hasMinLength", _descriptor7, this);
      _initializerDefineProperty(this, "hasTwoNumber", _descriptor8, this);
      _initializerDefineProperty(this, "hasSpecialChar", _descriptor9, this);
      _initializerDefineProperty(this, "hasPassConfirmation", _descriptor10, this);
      _initializerDefineProperty(this, "hasLength", _descriptor11, this);
      _initializerDefineProperty(this, "hasMaxRepeatChar", _descriptor12, this);
      _initializerDefineProperty(this, "hasMaxSequence", _descriptor13, this);
      _initializerDefineProperty(this, "hasUpperQtd", _descriptor14, this);
      _initializerDefineProperty(this, "hasNumbersQtd", _descriptor15, this);
      _initializerDefineProperty(this, "hasSymbolQtd", _descriptor16, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor17, this);
      _initializerDefineProperty(this, "companyPassProp", _descriptor18, this);
    }
    *resetPassword() {
      try {
        if (!this.password || !this.confirmPassword) {
          return this.swal.warning('O preenchimento dos campos são obrigatórios');
        }
        let response = yield this.fetch.post('resetpassword', {
          data: {
            token: this.token,
            password: this.password,
            confirmPassword: this.confirmPassword
          }
        });
        this.swal.success(response.message);
        document.location.href = _environment.default.appUrl.social;
      } catch (e) {
        const error = e.errors ? e.errors[0].detail : e;
        if (RegExp('Token*').test(error)) {
          yield this.swal.warning('Link expirou! O processo de recuperação precisa ser iniciado novamente.');
          this.router.transitionTo('forgot-password');
          return;
        }
        this.swal.catch(e);
      }
    }
    validatePassword() {
      this.hasMinLength = this.password.length >= 8 ? true : false;
      let numbers = this.password.match(/\d+/g);
      if (numbers !== null && numbers.length) {
        numbers = numbers.reduce((total, n) => total + n.split('').length, 0);
        this.hasTwoNumber = numbers >= 2 ? true : false;
      } else {
        this.hasTwoNumber = false;
      }
      const specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
      const containSpecialCharacters = specialCharacters.test(this.password);
      this.hasSpecialChar = containSpecialCharacters ? true : false;
      this.hasPassConfirmation = this.password && this.password === this.confirmPassword ? true : false;
    }
    validateCompanyPassProp() {
      this.hasLength = this.companyPassProp.passwordLengthMin <= this.password.length && this.password.length <= this.companyPassProp.passwordLengthMax;
      let repeat = 0;
      for (let i = 0; i < this.password.length; i++) {
        if (this.password[i] === this.password[i + 1]) {
          repeat = repeat + 1;
        }
      }
      this.hasMaxRepeatChar = repeat > this.companyPassProp.passwordMaxRepeatChar ? false : true;
      let sequence = 0;
      for (let i = 0; i < this.password.length; i++) {
        if (this.password.charCodeAt(i) === this.password.charCodeAt(i + 1) - 1) {
          sequence = sequence + 1;
        }
      }
      this.hasMaxSequence = sequence >= this.companyPassProp.passwordMaxSequence ? false : true;
      this.hasUpperQtd = this.companyPassProp.passwordUpperQtd <= (this.password.match(/[A-Z]/g) ? this.password.match(/[A-Z]/g).length : 0);
      this.hasNumbersQtd = this.companyPassProp.passwordNumbersQtd <= (this.password.match(/\d/g) ? this.password.match(/\d/g).length : 0);
      const escapedSpecialChars = this.companyPassProp.passwordSymbolAllowed.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const specialCharactersRegex = new RegExp(`[${escapedSpecialChars}]`, 'g');
      const specialCharacters = this.password.match(specialCharactersRegex);
      this.hasSymbolQtd = (specialCharacters ? specialCharacters.length : 0) >= this.companyPassProp.passwordSymbolQtd;
      this.hasPassConfirmation = this.password && this.password === this.confirmPassword;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "token", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmPassword", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasMinLength", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasTwoNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasSpecialChar", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasPassConfirmation", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hasLength", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasMaxRepeatChar", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasMaxSequence", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasUpperQtd", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "hasNumbersQtd", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasSymbolQtd", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "companyPassProp", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetPassword", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "resetPassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validatePassword", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "validatePassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateCompanyPassProp", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "validateCompanyPassProp"), _class.prototype)), _class));
  _exports.default = ResetPasswordController;
});