define("account-talentrh/pods/components/settings/details/authentication2FA/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Zd5g0E/C",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"text-justify text-contas\"],[12],[2,\"Defina a recorrência em que será solicitada a autenticação de dois fatores para os colaboradores.\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-checkbox\",[[24,0,\"mr-3\"]],[[\"@checked\",\"@onChange\"],[[30,[36,0],[[32,0,[\"companyConfig\",\"twoFactorAuthPeriod\"]],[32,1,[\"type\"]]],null],[30,[36,1],[[32,0],[32,0,[\"setType\"]],[32,1,[\"type\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"action\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "account-talentrh/pods/components/settings/details/authentication2FA/template.hbs"
    }
  });
  _exports.default = _default;
});