define("account-talentrh/pods/registry/general/user/edit/index/formation/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('args.user'), _dec12 = Ember.computed.alias('args.formations'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class FormationComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "openModal", _descriptor4, this);
      _initializerDefineProperty(this, "modalFormation", _descriptor5, this);
      _initializerDefineProperty(this, "modalTitle", _descriptor6, this);
      _initializerDefineProperty(this, "useOtherInstitution", _descriptor7, this);
      _initializerDefineProperty(this, "useOtherCourse", _descriptor8, this);
      _initializerDefineProperty(this, "formationType", _descriptor9, this);
      _initializerDefineProperty(this, "characterCount", _descriptor10, this);
      _initializerDefineProperty(this, "user", _descriptor11, this);
      _initializerDefineProperty(this, "formations", _descriptor12, this);
      _defineProperty(this, "graduationType", [{
        value: 'Técnico',
        label: 'Técnico'
      }, {
        value: 'Graduação',
        label: 'Graduação'
      }, {
        value: 'Especialização',
        label: 'Especialização'
      }, {
        value: 'MBA',
        label: 'MBA'
      }, {
        value: 'Mestrado',
        label: 'Mestrado'
      }, {
        value: 'Doutorado',
        label: 'Doutorado'
      }, {
        value: 'Pós-doutorado',
        label: 'Pós-doutorado'
      }, {
        value: 'Outros',
        label: 'Outros'
      }]);
    }
    get courseTooltip() {
      return this.useOtherCourse ? 'Selecionar curso' : 'Informar manualmente';
    }
    get institutionTooltip() {
      return this.useOtherInstitution ? 'Selecionar instituição' : 'Informar manualmente';
    }
    get showCourseName() {
      return this.modalFormation && this.modalFormation.otherCourse || this.useOtherCourse;
    }
    get showInstitutionName() {
      return this.modalFormation && this.modalFormation.otherInstitution || this.useOtherInstitution;
    }
    get quantityCharacteres() {
      if (this.modalFormation && this.modalFormation.acquiredknowledge) {
        this.characterCount = this.modalFormation.acquiredknowledge.length;
        return true;
      }
      return false;
    }
    get showGraduationLevel() {
      this.changeFormationType();
      return true;
    }
    *save() {
      let formation = this.modalFormation;
      let message = formation.isNew ? 'Formação cadastrada' : 'Formação atualizada';
      try {
        if (!formation.institution.get('id') && !formation.otherInstitution) {
          return this.swal.warning('Informe a instituição de ensino');
        }
        if (!formation.course.get('id') && !formation.otherCourse) {
          return this.swal.warning('Informe o curso');
        }
        if (!this.formationType) {
          return this.swal.warning('Informe o nível da formação');
        }
        if (this.formationType) {
          formation.type = this.formationType.label;
        }
        if (formation.isNew) {
          this.formations.addObject(formation);
        }
        yield formation.save();
        this.toast.success(message);
        this.openModal = false;
      } catch (e) {
        if (this.formations.includes(formation)) {
          this.formations.removeObject(formation);
        }
        this.swal.catch(e);
      }
    }
    countCharacteres() {
      let formation = this.modalFormation;
      this.characterCount = formation.acquiredknowledge.length;
    }
    changeFormationType() {
      let formation = this.modalFormation;
      if (formation && formation.type) {
        this.formationType = this.graduationType.findBy('label', formation.type);
      }
    }
    closeModal() {
      if (!this.modalFormation.isNew) {
        this.modalFormation.rollbackAttributes();
      }
      this.formationType = null;
    }
    openEditModal(formation) {
      this.modalFormation = formation;
      this.modalTitle = 'Editar curso/graduação';
      this.openModal = true;
    }
    openCreateModal() {
      this.modalFormation = this.store.createRecord('user-formation', {
        user: this.user
      });
      this.modalTitle = 'Incluir curso/graduação';
      this.openModal = true;
    }
    toggleCourse() {
      let formation = this.modalFormation;
      this.useOtherCourse = !this.useOtherCourse;
      if (formation.otherCourse) {
        this.useOtherCourse = false;
      }
      if (this.useOtherCourse) {
        formation.course = null;
      } else {
        formation.otherCourse = null;
      }
    }
    toggleInstitution() {
      let formation = this.modalFormation;
      this.useOtherInstitution = !this.useOtherInstitution;
      if (formation.otherInstitution) {
        this.useOtherInstitution = false;
      }
      if (this.useOtherInstitution) {
        formation.institution = null;
      } else {
        formation.otherInstitution = null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalFormation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "useOtherInstitution", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "useOtherCourse", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "formationType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "characterCount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "formations", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countCharacteres", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "countCharacteres"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeFormationType", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "changeFormationType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditModal", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "openEditModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCreateModal", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCourse", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCourse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInstitution", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInstitution"), _class.prototype)), _class));
  _exports.default = FormationComponent;
});